import styled from "styled-components";

interface LayoutProps {
    isOneItem: boolean
}

export const Layout = styled.div<LayoutProps>`
  padding: ${props => props.isOneItem ? '20px 80px' : '0px 80px'};
  padding-bottom: 20px;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: absolute;
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
`;

export const WelcomeContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40%;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
        flex-direction: column; 
        height: 30%;
    }
`

export const WelcomeImage = styled.img`
    max-height: 170px;
    height: auto;
    width: auto;
    position: relative;

    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const BrandDescription = styled.span`
`

export const WelcomeText = styled.div`
`

export const BrandLogo = styled.h1`
`

export const Brand = styled.div`
    display: flex;
    align-items: center;
`

export const BrandText = styled.h1`
    display: inline-block;
    word-wrap: break-word;
    white-space: nowrap;
    word-break: break-all;
`



