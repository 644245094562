import React, { FC, MutableRefObject } from "react";
import styled from "styled-components";

interface DraggableFile {
  ref: MutableRefObject<HTMLDivElement>;
}

type DivProps = React.HTMLProps<HTMLDivElement>

export const AntFileItem = React.forwardRef<HTMLDivElement, DivProps>(({ children, ...props }, ref) => (
  <div {...props} ref={ref} >{children}</div>
));

export type DraggedFrom = "up" | "down" | undefined;

export interface FileItemProps {
  isOver: boolean | undefined;
  draggedFrom: DraggedFrom;
}

export const FileItem = styled(AntFileItem)<FileItemProps>`
  cursor: move;
  border-top:  2px dashed rgba(0, 0, 0, 0);
  border-bottom: 2px dashed rgba(0, 0, 0, 0);
  ${({ isOver, draggedFrom }: FileItemProps) =>
    isOver &&
    (draggedFrom == "down"
      ? `
        border-bottom-color: #1890ff;
        border-bottom-width: 2;
        `
      : `
        border-top-color: #1890ff;
        border-top-width: 2;
    `)}
`;
