import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import Home from './views/Home'

function App() {
  return <Home />;
}

export default App;
