import {atom} from "jotai";
import { UploadFile } from "antd/lib/upload/interface";

interface File {
    name: string
}

export const fileListAtom = atom<UploadFile[]>([])
export const isEmptyFListAtom = atom<boolean>((get) => get(fileListAtom).length  == 0)
export const isConvertingAtom = atom<boolean>(false)
