import { Button } from "antd";
import styled from "styled-components";
import { Upload } from "antd";
import { DraggerProps } from "antd/lib/upload/Dragger";

const { Dragger } = Upload;
interface ConverterContainerProps {
  fullScreen: boolean;
}
export const ConverterContainer = styled.div<ConverterContainerProps>`
  background-color: white;
  position: relative;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.fullScreen ? "100%" : "60%")};
  justify-content: space-between;

  ${(props) =>
    !props.fullScreen &&
    `
    @media screen and (max-width: 576px) {
        height: 70%;
    }
  `}

  @media screen and (max-width: 400px) {
    padding: 0px;
  }
`;

export const ConverterActions = styled.div`
  display: flex;
  justify-content: center;
`;

interface StyledDraggerProps extends DraggerProps {
  isSmall: boolean;
}

export const StyledDragger = styled(Dragger)<StyledDraggerProps>`
  min-height: ${(props) => (props.isSmall ? "15vh" : "40vh")};
  ${(props) => (props.isSmall ? "max-height: 30vh" : "")}
`;
