import jsPDF from "jspdf";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const img = new Image();

      img.src = reader.result as string;

      while (!img.width && !img.height) {
        await sleep(5);
      }

      return resolve({
        src: reader.result,
        type: file.type,
        width: img.width,
        height: img.height,
      });
    };

    reader.onerror = (error) => reject(error);
  });

const convert = async (fileList: any[]) => {
  const docImage = new jsPDF();
  const imagesPromises = Array.from(fileList).map((f) =>
    toBase64(f.originFileObj)
  );
  const images = await Promise.all(imagesPromises);
  const pageWidth = docImage.internal.pageSize.getWidth();
  const pageHeight = docImage.internal.pageSize.getHeight();

  docImage.deletePage(1);

  images.forEach(({ src, type, width, height }: any, index) => {
    const relRatio = width / height;

    let w = 0;
    let h = 0;

    if (relRatio >= 1) {
      w = 210;
      h = 210 / relRatio;
      docImage.addPage([w, h], "l");
    } else if (relRatio < 1) {
      w = 297 * relRatio;
      h = 297;
      docImage.addPage([w, h], "p");
    }

    docImage.addImage(src, type, 0, 0, w, h);
  });

  docImage.save("document.pdf");
};

export default convert;
