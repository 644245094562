import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Tooltip } from "antd";
import { FileItem, DraggedFrom  } from "./style";

const type = "DragableUploadList";

const DraggableFileItem = ({ originNode, moveRow, file, fileList }: any) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const index = fileList.indexOf(file);

  const [{ isOver, draggedFrom }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        draggedFrom: (dragIndex < index ? "down" : "up") as DraggedFrom,
      };
    },
    drop: (item: any) => {
      return moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  const errorNode = (
    <Tooltip title="Upload Error" getPopupContainer={() => document.body}>
      {originNode.props.children}
    </Tooltip>
  );

  return (
    <FileItem ref={ref} isOver={isOver} draggedFrom={draggedFrom}>
      {file.status === "error" ? errorNode : originNode}
    </FileItem>
  );
};

export default DraggableFileItem;
