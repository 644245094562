import React, { useState } from "react";
import { Button } from "antd";
import DragAndDrop from "../Converter";
import {
  Brand,
  BrandDescription,
  BrandLogo,
  BrandText,
  Layout,
  WelcomeContainer,
  WelcomeImage,
  WelcomeText,
} from "./style";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import welocomeSvg from "../../icons/welcome.svg";
import { useAtom } from "jotai";
import { isEmptyFListAtom } from "../../atoms/convertation";

const Home = () => {
  const [isEmpty] = useAtom(isEmptyFListAtom);
  return (
    <Layout isOneItem={!isEmpty}>
      {isEmpty && (
        <WelcomeContainer>
          <WelcomeText>
            <Brand>
              <BrandText>converterocks</BrandText>
              <BrandLogo>🤘</BrandLogo>
            </Brand>
            <BrandDescription>
              Converterocks is free, fast and secure converter from image to pdf
            </BrandDescription>
          </WelcomeText>
          <WelcomeImage src={welocomeSvg} />
        </WelcomeContainer>
      )}
      <DragAndDrop />
    </Layout>
  );
};
export default Home;
