import React, { useCallback, useRef } from "react";
import { Button, Upload } from "antd";
import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import FileItem from "./FileItem";
import convert from "../../converter/imageToPdf";
import { useAtom } from "jotai";
import {
  fileListAtom,
  isConvertingAtom,
  isEmptyFListAtom,
} from "../../atoms/convertation";
import { ConverterActions, ConverterContainer, StyledDragger } from "./style";

const { Dragger } = Upload;

const RNDContext = createDndContext(HTML5Backend);

const type = "DragableUploadList";

const DragSortingUpload: React.FC = () => {
  const [fileList, setFileList] = useAtom(fileListAtom);
  const [isEmpty] = useAtom(isEmptyFListAtom);
  const [isConverting, setConverting] = useAtom(isConvertingAtom);

  const manager = useRef(RNDContext) as any;

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );

  const onChange = useCallback(({ fileList: newFileList, file }: any) => {
    setFileList(newFileList);
  }, []);

  const upload = useCallback((files: any) => {
    setConverting(true);
    convert(files).then(() => setConverting(false));
  }, []);

  return (
    <ConverterContainer fullScreen={!isEmpty}>
      <DndProvider manager={manager.current.dragDropManager}>
        <StyledDragger
          multiple={true}
          isSmall={!isEmpty}
          accept={"image/jpeg, image/png"}
          beforeUpload={() => false}
          fileList={fileList}
          onChange={onChange}
          listType="picture"
          itemRender={(originNode, file, currFileList) => (
            <FileItem
              originNode={originNode}
              onDragStart={(e: any) => e.preventDefault()}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </StyledDragger>
      </DndProvider>
      <ConverterActions>
        <Button
          type="primary"
          size="large"
          disabled={isEmpty}
          style={{ height: "50px", width: "170px" }}
          icon={<DownloadOutlined />}
          loading={isConverting}
          onClick={() => upload(fileList)}
        >
          Convert
        </Button>
      </ConverterActions>
    </ConverterContainer>
  );
};

export default DragSortingUpload;
